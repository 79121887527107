import React from "react";
import { graphql, Link } from "gatsby";
import Page from "../components/Layout/Page";
import { Query } from "..";
import GatsbyImage, { FluidObject } from "gatsby-image";
import ExternalLink from "../components/Elements/ExternalLink";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";

export const TitledImage = ({ title, subtitle, className = "", children }) => (
    <figure
        className={`p-10 bg-gray-800 text-white flex justify-center m-0 object-contain hover:bg-gray-900 transition flex-col items-center ${className}`}
    >
        <header className={"font-sans text-center mb-10"}>
            <h1
                className={"my-0 font-serif font-hairline text-2xl md:text-5xl"}
            >
                {title}
            </h1>
            <h2>{subtitle}</h2>
        </header>
        {children}
    </figure>
);

const TodayIsSunday = ({
    data: {
        allTodayIsSundayJson: { totalCount, nodes: renders },
    },
}: {
    data: Query;
}) => (
    <Page title={pageTitles.todayIsSunday}>
        <PageHeader
            color={pageColors.todayIsSunday}
            counts={[`${totalCount} scenes`]}
        >
            <strong>Today is Sunday</strong> was a series of in-game renders
            made in{" "}
            <ExternalLink
                href="https://store.steampowered.com/app/4000/Garrys_Mod/"
                className={"text-white"}
            >
                Garry's Mod
            </ExternalLink>{" "}
            from 2007 to 2009. All these scenes depict very dark, sometimes
            graphic imagery that overall showcase some of the worst aspects of
            life.
            <br />
            Some scenes are illustrations of some of my short stories, some are
            more contemplative, but most are aimed at depicting very hard
            things.
        </PageHeader>
        {renders.map((render) => (
            <TitledImage
                key={render.id}
                title={render.image.name}
                subtitle={render.date}
            >
                <Link
                    to={render.image.childImageSharp.original.src}
                    className={"w-full"}
                    style={{
                        maxWidth:
                            render.image.childImageSharp.fluid
                                .presentationWidth,
                    }}
                >
                    <GatsbyImage
                        fluid={
                            render.image.childImageSharp.fluid as FluidObject
                        }
                        className={"shadow-xl"}
                        imgStyle={{ objectFit: "contain" }}
                    />
                </Link>
            </TitledImage>
        ))}
    </Page>
);

export const query = graphql`
    {
        allTodayIsSundayJson(sort: { fields: date, order: DESC }) {
            totalCount
            nodes {
                id
                date(formatString: "MMMM YYYY")
                image {
                    name
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1150) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                        }
                        original {
                            src
                        }
                    }
                }
            }
        }
    }
`;

export default TodayIsSunday;
